.ui__component--drop__down--list {
    width: 100%;
    margin-top: 2px;
}

.ui__component--drop__down--list__name {
    border: 1px solid var(--ui-color-drop-down-border);
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 6px 10px;
}

.ui__component--drop__down--list__child--num {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 6px;
    margin: 0px 4px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: var(--ui-color-drop-down-child-num-color);
    background-color: var(--ui-color-drop-down-child-num-background);
}