.search_result_component{
    border-bottom: 1px solid lightgrey;
}

.search_result_component_head {
    font-weight: 500;
    font-size: 14px;
    color: var(--ui-color-light-search-color-head);
    padding: 12px 16px;
}

.search_result_component_body {

}

.search_result_component_body:hover {

}

.search_result_component found-entry{
    background-color: var(--ui-color-light-search-color-found-text);
    color: var(--ui-color-light-search-color-found-another-text);
    border-radius: 3px;
    padding: 0px 3px;
    margin: 1px;
}

/* task */
.search_result_component-task {
    padding: 16px;
}

.search_result_component-task:hover {
    background-color: var(--ui-color-lifht-search-background-hover);
    cursor: pointer;
}

.search_result_component-number{
    font-weight: 500;
    font-size: 12px;
    color: var(--ui-color-light-search-color-default-text);
}

.search_result_component-task-name{
    font-weight: 600;
    font-size: 14px;
    margin: 4px 0px;
    color: var(--ui-color-light-search-color-searchable-text);
}

.search_result_component-task-description {
    font-weight: 400;
    font-size: 12px;
    color: var(--ui-color-light-search-color-default-text);
}

/* board */
.search_result_component-board {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    padding: 12px 16px;
}

.search_result_component-board:hover {
    background-color: var(--ui-color-lifht-search-background-hover);
    cursor: pointer;
}

.search_result_component-board img {
    width: 28px;
    height: 28px;
}

/* project */
.search_result_component-project {
    align-items: center;
    display: flex;
    font-weight: 600;
    font-size: 14px;
    padding: 12px 16px;
}

.search_result_component-project:hover {
    background-color: var(--ui-color-lifht-search-background-hover);
    cursor: pointer;
}

.search_result_component-project img {
    width: 90px;
    height: 60px;
    border-radius: 6px;
    margin-right: 20px;
}

.search_result_component a {
    font-weight: 600;
    font-size: 16px;
    width: 220px;
    text-overflow: ellipsis;
    cursor: pointer;
    position: relative;
    z-index: 0;
    word-break: break-word;
    /*line-height: 19px;*/
    letter-spacing: -0.2px;
    color: #212121;
    text-decoration: none;
}

.search_result_component-description{
    font-weight: 400;
    font-size: 12px;
    /*line-height: 16px;*/
    color: gray

}

.search_result_component p{
    margin:0px !important;
}