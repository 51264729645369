/*.genInfo_body {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    margin-top: 15px;*/
/*}*/

/*.genInfo_body_item {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: space-between;*/
/*    margin-bottom: 12px;*/
/*    margin-left: 10px;*/
/*}*/

/*.genInfo_body .p-progressbar{*/
/*    background: var(--background-color-body);*/
/*    height: 8px;*/
/*}*/
/*.genInfo_body .p-progressbar-label{*/
/*    display: none;*/
/*}*/

/*.genInfo_body .p-progressbar-value{*/
/*    background: var(--background-color-blue);*/
/*}*/

/*.genInfo_heigth{*/
/*    height: 197px;*/
/*}*/

/*.genInfo-bigmode-header-wrapper {*/
/*    width: 100%;*/
/*    !*height: 33%;*!*/
/*    border: 1px red dotted;*/
/*    display: flex;*/
/*    flex-direction: row;*/

/*    max-height: fit-content;*/
/*}*/

/*.genInfo-bigmode-body-wrapper {*/
/*    width: 100%;*/
/*    height: 53vh;*/
/*    border: 1px blue dotted;*/
/*}*/

/*.big-fat-progress-bar-wrapper {*/
/*    !*border: 1px green dotted;*!*/
/*    flex-grow: 1;*/
/*    padding: 16px 16px 16px 0;*/
/*}*/

/*.big-fat-progress-bar {*/
/*    border-radius: 6px;*/
/*    box-shadow: 5px 4px 8px 0px #75848A1F;*/
/*    !*padding: 16px;*!*/
/*    height: 100%;*/
/*    position: relative;*/
/*}*/

/*.progress-bar-render {*/
/*    border-radius: 6px;*/
/*    !*border: 1px red dotted;*!*/
/*    !*position: absolute;*!*/
/*    height: 100%;*/
/*    !*width: 5%;*!*/
/*    !*background: linear-gradient(to right, var(--big-fat-progress-bar) 90%, rgba(0, 0, 0, 0));*!*/
/*    padding: 16px;*/
/*}*/

/*.filters-and-selectors-wrapper {*/
/*    !*border: 1px blue dotted;*!*/
/*    !*background: #fff;*!*/
/*    padding: 16px 8px 16px 0px;*/
/*    min-width: 25%;*/
/*    !*width: 15%;*!*/
/*}*/

/*.plot-block-wrapper {*/
/*    width: 100%;*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*}*/

/*.plot-block-wrapper .graph-wrapper {*/
/*    flex-grow: 2;*/
/*    !*height: 500px;*!*/
/*    !*position: relative;*!*/
/*    width: 100%;*/
/*    !*max-width: 80%;*!*/
/*    overflow-y: auto;*/
/*    scrollbar-gutter: stable;*/

/*    display: flex;*/
/*    flex-direction: row;*/
/*}*/

/*.graph-wrapper canvas{*/
/*    !*height: 100% !important;*!*/
/*}*/

/*.plot-block-wrapper .full-legend-wrapper {*/
/*    border: 1px red dotted;*/
/*    flex-grow: 1;*/
/*}*/





