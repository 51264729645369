
.task_checklist_elem_container {
    margin-left: 78px;
}

.task_checklist_elem {
    border: solid;
    border-color: rgba(146, 146, 146, 0.493);
    border-width: 1px;
    border-radius: 5px;
    display: flex;
    flex-wrap: nowrap;
    margin: 10px 0px;
    align-items: center;
}


.task_checklist_elem.renameMode {
    border: solid;
    border-color: red;
    border-width: 1px;
    border-radius: 5px;
    display: flex;
    flex-wrap: nowrap;
    margin: 10px 0px;
    align-items: center;
}

.task_checklist_elem.renameMode:hover {
    border-color: var(--background-color-blue); /* Синий цвет при фокусе */
}




.dragDrop__edit {
    width: 50px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dragDrop__edit:hover {
    cursor: pointer;
}

.task_checklist_group__block {
    display: flex;
    align-items: center;
    width: 100%;
}

.dragDrop__edit img {
    width: 35px;
    height: 40px;
}

.drag-button {
    font-size: small;
}

.task_checklist_elem__block--name {
    width: 90%;
    word-break: break-word;
}

.task_checklist_elem__block--name.checked{
    text-decoration: line-through;
}

.task_checklist_elem__block--name input {
    width: 90%;
    height: 40px;
    border-radius: 6px;
    border: none;
    /*border-color: rgba(146, 146, 146, 0.493);*/
}

.task_checklist_elem__block--name input:focus-visible {
    outline: none;
}

.task_checklist_elem__block--name-add {
    width: 90%;
}

.task_checklist_elem__block--name-add input {
    width: 90%;
    height: 40px;
    border-radius: 6px;
    border: none;
    /*border-color: rgba(146, 146, 146, 0.493);*/
}

.task_checklist_elem__block--name-add input:focus-visible {
    outline: none;
}

.task_checklist_elem__block__button {
    display: flex;
    align-items: center;
}

.task_checklist_elem__block__button button {
    margin: 5px;
    background-color: rgba(240, 248, 255, 0);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    width: 30px;
    height: 30px;
}

.task_checklist_elem__block__button button:focus {
    cursor: pointer;
    background-color: #149cce6b;
}

.drag-hidden {
    display: none;
}

.task_checklist_groups {
    /*background-color: white;*/
    /* padding: 30px 0px; */
    /* border: dotted; */
    /*border-radius: 6px;*/
}

.task_checklist_elem__block {
    display: flex;
    align-items: center;
    width: 100%;
}

.task_checklist_elem__block__input {
    border: solid;
    border-color: rgba(146, 146, 146, 0.493);
    border-width: 1px;
    border-radius: 5px;
    display: flex;
    flex-wrap: nowrap;
    margin: 10px 0px;
    margin-left: 50px;
    align-items: center;
    /*display: flex;*/
    /*align-items: center;*/
}

.checklist-chat {

}

.postive-green-button {
    cursor: pointer;
    border-radius: 1000px;
    padding: 5px;
    border: none;
    width: 30px;
    height: 30px;
    background-color: var(--positive-green-button-background-color) !important;
}

.postive-green-button.right-aligned {
    margin-left: auto;
    margin-right: 24px;
}

.postive-green-button:hover {
    box-shadow: 0 0 8px var(--positive-green-button-background-color) !important;
}

.grey-cancel-button {
    margin-left: auto;
    width: 30px;
    height: 30px;
    border-radius: 10000px;
    border: none;
    cursor: pointer;
}

.grey-cancel-button:hover {
    background-color: var(--ui-color-button-hover-light);
    box-shadow: 0 0 8px var(--ui-color-button-hover-light) !important;
}