.menu-container {
    background: var(--menu-navigations-color-white);
    box-sizing: border-box;
    width: 280px;
    height: 100vh;
    z-index: 1000;
    border-color: #e1e7ea;
    border-style: solid;
    border-width: 0px 1px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.menu-container.collapsed {
    width: 80px;
}

.menu-header {
    z-index: 1000;
    background: var(--menu-navigations-color-white);
    padding: 10px;
    width: 100%;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 0px;
    height: 10%;
}

.menu-header .logo {
    max-width: 100%;
    padding: 0px 5px;
    cursor: pointer;
    max-height: 100%;
}

.collapse-button {
    position: absolute;
    right: -15px;
    padding: 0px;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    border: 1px solid #D7DCE3;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-nav-panel {
    height: 85%;
    overflow-y: scroll;
    width: 100%;
}

.menu-container.collapsed .menu-nav-panel {
    height: 90%;   
}

.menu-footer {
    padding: 10px 0px 20px 30px;
    height: 5%;
    background: var(--menu-navigations-color-white);
}

.wiki-link {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: var(--blue-500, #007caa);
    text-decoration: underline;
    cursor: pointer;
}
