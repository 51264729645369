.board-toolbar {
    padding: 5px !important;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background-color: white;
    margin: 0px 13px 0px 13px;
    border-radius: 8px;
    justify-content: space-between;
    align-items: center;
    height: 55px;
}

.toolbar__point--task__counter {
    text-align: center;
    margin: 10px;
    width: 10px;
    font-family: Inter;
    color: var(--table-header-color);
}

.board-toolbar .toolbar-left {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.board-toolbar .toolbar-right {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    position: relative;
}

.board-toolbar .toolbar-right .mass-actions {
    display: flex;
    flex-direction: row;
}


.move-task-popup-container { 
    position: relative;
    z-index: 1000;
  }
  
.move-task-popup-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh
}

.move-task-popup {
    position: absolute;
    top: 50px;
    right: -220px;
    width: fit-content;
    height: fit-content;
    z-index: 1000;
    background-color: white;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0px 4px 8px 3px #00000026;
}

.move-task-popup-column-container {
    max-width: 240px;
    max-height: 320px;
    overflow: scroll;
}

.move-task-popup-header {
    margin: 0px;
    font-family: Inter;
    font-size: 15px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: left;
    color: var(--content-content-secondary, #75848A);
    padding: 10px;
}


.move-task-popup-column-text:hover {
    color: var(--background-color-blue);
    background: linear-gradient(0deg, rgba(14, 156, 213, 0.04), rgba(14, 156, 213, 0.04)), #FFFFFF;
    box-shadow: inset 0px 2px 4px rgba(255, 255, 255, 0.12);
}

.move-task-popup-column-text {
    padding: 10px;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: var(--content-content-primary, #12191C);
}
