.ui__component--checkbox-default {
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.ui__component--checkbox-default input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    cursor: pointer;
}

.ui__component--checkbox-default input[type="checkbox"] + span {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 4px;
    transition: background-color 0.3s, border-color 0.3s;
}

.ui__component--checkbox-default input[type="checkbox"]:checked + span {
    background-color: var(--ui-color-default-chekbox-cheked);
    border-color: var(--ui-color-default-chekbox-cheked);
}

.ui__component--checkbox-default input[type="checkbox"]:checked + span::after {
    content: "✔";
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 14px;
}

/* Стили для заблокированного чекбокса */
.ui__component--checkbox-default input[type="checkbox"]:disabled + span {
    background-color: #f0f0f0; /* Серый фон для заблокированного чекбокса */
    border-color: #ccc; /* Серый цвет границы */
    cursor: not-allowed; /* Курсор "недоступно" */
}

/* Крестик для заблокированного чекбокса */
.ui__component--checkbox-default input[type="checkbox"]:disabled + span::after {
    content: "\2715";
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: var(--ui-color-ios-chekbox-disabled);
}