:root {
    --result-background-color: #75848a0a;
    --result-border-color: #e1e7ea;
    --description-text-color: #56666c;
}

.search-filters-container {
    min-height: auto;
    margin: 30px 30px 0px 30px;
    padding: 20px;
}

.search-results-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.search-result-container {
    /*max-width: 70.5%;*/
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--result-background-color);
    border-radius: 6px;
    border: 1px var(--result-border-color) solid;
    padding: 8px;
}

.search-result-img {
    border-radius: 6px;
}

.search-result-id {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0%;
    color: var(--description-text-color);
}

.search-result-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0%;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.search-result-name found-entry {
    background-color: var(--ui-color-search-global-color-found-text);
    color: var(--ui-color-search-global-color-found-another-text);
    border-radius: 3px;
    padding: 0px 3px;
    margin: 1px;
}

.search_result_component-number found-entry {
    background-color: var(--ui-color-search-global-color-found-text);
    color: var(--ui-color-search-global-color-found-another-text);
    border-radius: 3px;
    padding: 0px 3px;
    margin: 1px;
}

.search-result-description {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0%;
    color: var(--description-text-color);
}

.search-result-description found-entry {
    background-color: var(--ui-color-search-global-color-found-text);
    color: var(--ui-color-search-global-color-found-another-text);
    border-radius: 3px;
    padding: 0px 3px;
    margin: 1px;
}

.search-result-header {
    display: flex;
    align-items: center;
}

.search-result-heading {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0%;
}

.search-result-header {
    display: flex;
}

.search-result-searchable {
    border-radius: 6px;
    padding: 4px 8px;
    margin: 0px 2px;
    background-color: var(--ui-color-search-global-color-searchable-background);
}

.search-result-progress {
    width: 140px;
}

.search-result-progress a {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
    color: var(--ui-color-search-global-color-percentage);
}

.search-result-task-users {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: right;
    flex-wrap: nowrap;
    border-left: 1px solid
        var(--ui-color-search-global-color-current-search-background);
    padding-left: 10px;
    padding-right: 10px;
    min-width: 35%;
}

.search-result-task-tags {
    display: flex;
    justify-content: right;
    flex-wrap: wrap;
    width: 250px;
    margin: 0px 10px;
    border-left: 1px solid
        var(--ui-color-search-global-color-current-search-background);
}

.search-result-task-type {
    display: flex;
    margin: 0px 10px;
    flex-direction: column;
    border-left: 1px solid
        var(--ui-color-search-global-color-current-search-background);
    padding-left: 10px;
    padding-right: 10px;
    justify-content: start;
    min-width: 30%;
}

.search-result-task-left {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: 50%;
}

.search-result-task-middle {
    width: 35%;
    justify-content: end;
}

.search-result-task-right {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: end;
}

.search-result-task-projectName {
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
