:root {
    --widget-color-background: #FFFFFF;
    --widget-color-shadow: #75848A1F;

    --index: calc(1vw + 1vh);
}

.page__basket {
    padding: 20px;
    width: 100%;
    height: 91vh;
    overflow: hidden;
}

.page__basket--scroll {
    overflow-y: scroll;
    max-height: calc(var(--index) * 18);
}

.page__basket--cell {
    width: calc(var(--index) * 6);
    max-width: calc(var(--index) * 6);

}

.page__basket--cell__main {
    flex-grow: 1;
    width: calc(var(--index) * 10);
    white-space: wrap;
    overflow: hidden;
}

.page__basket--cell__reverse {
    width: calc(var(--index) * 2);
}