.ui__component--animated__search {
    width: 34px;
    height: 34px;
}

.ui__component--animated__search--abs__pos {
    width: 34px;
    height: 34px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: right;
}

.ui__component--animated__search--pos {

}

.ui__component--animated__search--pos__input {
    display: flex;
    background: var(--ui-color-animated-search-button-background);
    border: 1px solid var(--ui-color-animated-search-button-border);
    border-radius: 6px;
}

.ui__component--animated__search--pos__input--non__color {
    display: flex;
    border-radius: 6px;
}

.ui__component--animated__search--body {
    width: 34px;
    height: 34px;
    transition: width 0.5s ease-in-out, padding-left 1s ease-in-out;
}

.ui__component--animated__search--body.moved {
    /*width: 300px;*/
}

.ui__component--animated__search--button {
    width: 34px;
    height: 34px;
    padding: 7px;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 6px;
}

.ui__component--animated__search--pos input{
    padding-left: 15px;
    border: none;
    border-radius: 6px;
    color: var(--ui-color-animated-search-input-color);
}

.ui__component--animated__search--pos input:focus{
    outline: none;
    border: none;
}

.ui__component--animated__search--child {
    width: 0px;
    height: 0px;
    transition: width 0.5s ease-in-out;
}

.ui__component--animated__search--pos__child {
    box-shadow: 5px 4px 4px 0px var(--widget-color-shadow), -5px 4px 4px 0px var(--widget-color-shadow);
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: var(--ui-color-animated-search-button-border);
    background-color: var(--ui-color-animated-search-child-background);
    border-radius: 6px;
    position: relative;
    z-index: 2000;
    overflow: hidden;
    /*top: 10px;*/
}