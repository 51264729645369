.custom_search {
    /* display: flex;
    flex-direction: row; */
    margin: 5px;
    height: 36px;
    min-width: 150px;
    justify-content: space-between;
    align-items: center;
    box-sizing: unset;

    /* Внутренняя граница */
    outline-offset: -2px;
    /* Смещение внутрь */
    margin-left: 5px;


    border: solid 1px #E1E7EA;
    border-radius: 6px;

}

.search:focus-within{
    outline: none!important;
    border: solid 1px #149cce !important;
}

.custom_search img {
    padding: 5px;
    margin: 5px;
}

.custom_search-form {
    width: 100%;
}

.custom_search img:hover {
    cursor: pointer;
}

.custom_search input {
    width: 100%;
    height: 36px;
    padding-left: 20px;
    border: 0px;
    background: none;

}

.custom_search input[type="search"]:focus {
    outline: none;
    /* Убираем выделение при фокусировке */
}

.custom_search {
    background: white;
    display: flex;
    border-radius: 6px;
}