.search-filters-container .line-person-display-widget {
    height: auto;
}

.search-global-filter-container {

}

.search-global-filter-block {
    display: flex;
    align-items: center;
    height: 36px;
    margin-top: 12px;
}

.shared-filters-container {
    display: flex;
    gap: 10px;
    margin-top: 12px;
}

.search-filters-main-search {
    margin: 0px;
    height: 36px;
    width: 100%;
    margin-right: 10px;
}

.search-filters-main-search input {
    padding-left: 10px;
    height: 36px;
}

.search-global-filter-block-error {
    width: max-content;
    margin: 4px 0px;
    padding: 2px 6px;
    border-radius: 6px;
    font-size: 15px;
    color: var(--ui-color-search-global-color-error-color);
    background-color: var(--ui-color-search-global-color-error-background);
}

.search-filters-main-search-error {
    border: 1px solid var(--ui-color-search-global-color-error-border);
}

.search-global-filter-block-current-search {
    display: flex;
    align-items: center;
    width: max-content;
    margin: 4px 4px 4px 0px;
    padding: 2px 6px;
    border-radius: 6px;
    font-size: 15px;
    color: var(--ui-color-search-global-color-current-search-color);
    background-color: var(--ui-color-search-global-color-current-search-background);
}

.search-global-filter-block-current-search a{
    margin-right: 4px;
}

.search-global-filter-block-current-search img{
    width: 12px;
    height: 12px;
    padding: 2px;
    margin-left: 4px;
    border-radius: 2px;
}

.search-global-filter-block-current-search img:hover{
    cursor: pointer;
    background-color: var(--ui-color-search-global-color-current-search-background);
}

.search-global-button {
    height: 36px;
    font-size: 14px;
    font-weight: 500;
    color: var(--ui-color-search-global-color-button);
    background-color: var(--ui-color-search-global-color-background);
}

.search-global-button:hover {
    cursor: pointer;
    background-color: var(--ui-color-search-global-color-background-hover);
}

.search-global-filter-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.search-global-filter-button:hover {
    cursor: pointer;
    background-color: var(--ui-color-search-global-color-background-hover);
}

.search-filter-label {
    margin: 0px;
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0%;
    color: var(--text-color-widgets-gray);
}

.filter-width-container {
    min-width: 33%;
}

.search-filters-user-selected {
}

.search-filters-user-options {
    padding: 10px 5px;
    border-radius: 6px;
}

.search-filters-user-options:hover {
    cursor: pointer;
    background-color: var(--hover-color-blue);
}

.search-filters-type-options {
    padding: 5px 5px;
    border-radius: 6px;
    
}

.search-filters-type-options:hover {
    cursor: pointer;
}

.selected-option {
    margin: 0px;
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0%;
    color: var(--text-color-widgets-gray);
}

.selected-option .task-type-text {
    line-height: 14px;
}
.task-type-text{
    width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.search-filters-container {
    display: flex;
}

.search-filters-container .ui__component--tab__navigator {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.search-filters-container .tab-navigator__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
