input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.button-base {
    height: 36px;
    width: 36px;
    background-color: transparent;
    border-radius: 6px;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-base:hover {
    background-color: var(--hover-color-gray-blue);
    /*background-color: red;*/
    cursor: pointer;
}

.button-base:focus{
    outline: none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.05rem #bfdbfe;
    border-color: #3b82f6;
}

.counter-text {
    font-family: Inter;
    font-size: 8px;
    font-weight: 800;
    line-height: 9.68px;
    text-align: center;
}

.button_copy p {
    position: absolute;
    top: 135px;
    left: 450px;
}

.show {
    opacity: 1;
    transition: opacity 200ms;
}

.hide {
    opacity: 0;
    transition: opacity 200ms;
}

.margin_btn{
    margin-left: 5px;
}

.text-button-base {
    padding: 8px;
    border-radius: 6px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: Inter;
    font-weight: 500;
    font-size: 14px;

    cursor: pointer;
}

.text-button-base:focus{
    outline: 1px solid #2682a4;
    outline-offset: 0;
}

.text-button-base.gray-button {
    background-color: var(--gray-button-background);
}

.text-button-base.gray-button:hover {
    background-color: var(--gray-button-background-hover);
}

.text-button-base.blue-button {
    background-color: var(--text-color-widgets-blue);
}

.text-button-base.blue-button:hover {
    background-color: var(--blue-button-background-hover);
}

.text-button-base.blue-font {
    color: var(--text-color-widgets-blue);
}

.text-button-base.white-font {
    color: var(--global-white-full);
}




