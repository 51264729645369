.small-progress-bar .p-progressbar{
  background: var(--background-color-body);
  height: 8px;
}
.small-progress-bar .p-progressbar-label{
  display: none;
}

.small-progress-bar .p-progressbar-value{
  background: var(--background-color-blue);
}