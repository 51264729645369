.ui__component--tooltip {

}

.ui__component--tooltip__show{
    /*overflow: hidden;*/
    /*text-overflow: ellipsis;*/
    /*-webkit-line-clamp: 1;*/
    /*display: -webkit-box;*/
    /*-webkit-box-orient: vertical;*/
}

/*.ui__component--tooltip__show .ui__component--tooltip__block .ui__component--tooltip__text {*/
/*    visibility: hidden;*/
/*    position: absolute;*/
/*    z-index: 900000;*/
/*    top: 27px;*/
/*    min-width: 500px;*/
/*    max-width: 500px;*/
/*    border-radius: 6px;*/
/*    font-size: 12px;*/
/*    padding: 4px;*/
/*    background-color: var(--ui-color-custom-tooltip-background);*/
/*    color: var(--ui-color-custom-tooltip-color);*/
/*    border: 1px solid var(--ui-color-custom-tooltip-border);*/
/*}*/

/*.ui__component--tooltip__show:hover .ui__component--tooltip__block .ui__component--tooltip__text {*/
/*    visibility: visible;*/

/*}*/

/*.tooltip {*/
/*    position: relative;*/
/*    text-decoration: none; !* Убираем стандартное подчёркивание *!*/
/*}*/

/*.tooltip[title]:hover:after {*/
/*    color: #149cce;*/
/*}*/

/*.tooltip::after {*/
/*    content: attr(data-tooltip);*/
/*    position: relative;*/
/*    !*top: 20px; !* Можно подстроить это значение под себя *!*!*/
/*    !*left: 0;*!*/
/*    z-index: 10000; !* Подсказка будет отображаться поверх других элементов *!*/
/*    background: #333;*/
/*    color: #fff;*/
/*    padding: 5px 10px;*/
/*    border-radius: 3px;*/
/*    width: 400px;*/
/*    display: block;*/
/*    !*display: none; !* По умолчанию скрыта *!*!*/
/*    !*white-space: nowrap; !* Гарантирует, что текст не будет переноситься на следующую строку *!*!*/
/*    overflow: unset;*/
/*    text-overflow: unset;*/
/*    -webkit-line-clamp: unset;*/
/*    !*display: unset;*!*/
/*    -webkit-box-orient: unset;*/
/*}*/
/*.tooltip:hover::after {*/
/*    display: block; !* При наведении показываем подсказку *!*/
/*}*/