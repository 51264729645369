.person-with-subtext-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.person-with-subtex-name,
.person-with-subtext-subtext {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.person-with-subtex-name {
}

.person-with-subtext-subtext {
    color: #56666c;
}

.person-with-subtext-img {
    width: 32px;
    height: 32px;
    margin-right: 8px;
}
