.ui__component--checkbox-ios {
    display: inline-block;
    height: 28px;
    line-height: 28px;
    position: relative;
    vertical-align: middle;
    font-size: 14px;
    user-select: none;
}
.ui__component--checkbox-ios .ui__component--checkbox-ios-switch {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    width: 50px;
    height: 28px;
    /*border: 1px solid rgba(0, 0, 0, .1);*/
    border-radius: 25%/50%;
    vertical-align: top;
    background: #eee;
    transition: .2s;
}
.ui__component--checkbox-ios .ui__component--checkbox-ios-switch:before {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: white;
    box-shadow: 0 3px 5px #23273014;
    transition: .12s;
}
.ui__component--checkbox-ios input[type=checkbox] {
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.ui__component--checkbox-ios input[type=checkbox]:not(:disabled):active + .ui__component--checkbox-ios-switch:before {
    box-shadow: inset 0 0 2px rgba(0, 0, 0, .3);
}
.ui__component--checkbox-ios input[type=checkbox]:checked + .ui__component--checkbox-ios-switch {
    background: var(--ui-color-ios-chekbox-cheked);
}
.ui__component--checkbox-ios input[type=checkbox]:checked + .ui__component--checkbox-ios-switch:before {
    transform:translateX(22px);
}

/* Hover */
.ui__component--checkbox-ios input[type="checkbox"]:not(:disabled) + .ui__component--checkbox-ios-switch {
    cursor: pointer;
    border-color: rgba(0, 0, 0, .3);
}

/* Disabled */
.ui__component--checkbox-ios input[type=checkbox]:disabled + .ui__component--checkbox-ios-switch {
    filter: grayscale(70%);
    border-color: var(--ui-color-ios-chekbox-disabled);
    cursor: not-allowed; /* Курсор "недоступно" */
}
.ui__component--checkbox-ios input[type=checkbox]:disabled + .ui__component--checkbox-ios-switch:before {
    background: #F5F6F8;
}

/* Focus */
.ui__component--checkbox-ios.focused .ui__component--checkbox-ios-switch:before {
    box-shadow: inset 0px 0px 4px var(--ui-color-ios-chekbox-cheked);
}