.notification--block__header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
}

.notification--block__header h3 {
    margin-right: 40px;
    font-weight: 600;
    font-size: 20px;
    color: var(--ui-color-notification-header-h3);
}

.notification--block__header p {
    margin-right: 12px;
    font-weight: 500;
    font-size: 14px;
    color: var(--ui-color-notification-header-p);
}

.notification--block__body {
    overflow-y: auto;
    padding-right: 2px;
}

.mention__block--current {
    height: 110px;
    padding: 5px;
    margin-top: 2px;
    display: flex;
}

.mention__block--current:hover {
    background-color: var(--ui-color-notification-hover);
}

.mention__block--current__not--viewed {
    background-color: var(--ui-color-notification-not-viewed);
    border-radius: 4px;
}

.mention__block--current__img img {
    width: 32px;
    height: 32px;
}

.mention__block--current__body {
    margin-left: 5px;
    width: 100%;
}

.mention__block--current__name a{
    font-size: 14px;
    font-weight: 600;
    margin: 0px 4px;
}

.mention__block--current__name--gray {
    font-size: 14px;
    font-weight: 500;
    color: var(--ui-color-notification-header-p);
}

.mention__block--current__markdown {
    border: 1px solid var(--ui-color-notification-header-p);
    padding: 4px;
    border-radius: 4px;
    height: 70px;
}

.mention__block--current__markdown--block {
    height: 40px;
    overflow: hidden;
    width: 200px;
}

.mention__block--current__markdown--block__text {
    /*padding: 5px;*/
    padding-top: 8px;
    font-size: 14px;
    font-weight: 400;
    color: #75848a;
    width: 220px;
    max-height: 50px;
}

.mention__block--current__markdown p {
    height: 40px; 
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.mention__block--current__markdown--text{
    display: flex;
    justify-content: right;
    color: var(--ui-color-notification-text-color);
    border-bottom: 1px solid #00000000;
}

.mention__block--current__markdown--text__go--to {
    font-size: 14px;
}

.mention__block--current__markdown--text__go--to:hover {
    cursor: pointer;
    border-bottom: 1px solid var(--ui-color-notification-text-color);
}

.mention__block--current__read {
    width: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mention__block--current__read--point {
    width: 15px;
    height: 15px;
    border-radius: 32px;
    background-color: var(--ui-color-notification-point-color);
    outline: 3px solid var(--ui-color-notification-point-color-border);
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.7);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.7);
    }
}