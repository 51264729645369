.report-special-table-container {
    height: 100%;
    flex: 1;
    overflow-x: auto;
}

.report-special-table-wrapper {
    flex: 1;
    position: relative;
    height: 100%;
}

#report-special-table {
    width: 100%;
    border-collapse: collapse;
}

#report-special-table thead {
    background-color: var(--background-color-white);
}

#report-special-table thead tr:hover{
    background-color: transparent;
    cursor: auto;
}

#report-special-table th {
    font-size: 14px;
    text-align: left;
    padding: 0 16px 16px 16px;
}

#report-special-table td {
    padding: 6px 0 6px 16px;
    max-width: 20%;
}

.td-inner-limiter {
    word-break: break-word;
}

#report-special-table tr {

}

#report-special-table tr:hover {
    background-color: var(--table-row-hover);
    cursor: pointer;
}

#report-special-table .table-row-striped-highlight {
    background-color: var(--table-row-striped-highlight);
}

.genInfo_body {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.genInfo_body_item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;
    margin-left: 10px;
}

.genInfo_body .p-progressbar{
    background: var(--background-color-body);
    height: 8px;
}
.genInfo_body .p-progressbar-label{
    display: none;
}

.genInfo_body .p-progressbar-value{
    background: var(--background-color-blue);
}

.genInfo_heigth{
    /*height: 197px;*/
}

.genInfo-bigmode-header-wrapper {
    width: 100%;
    /*height: 33%;*/
    /*border: 1px red dotted;*/
    display: flex;
    flex-direction: row;

    max-height: fit-content;
}

.genInfo-bigmode-body-wrapper {
    width: 100%;
    /*height: 53vh;*/
    flex: 1;
}

.big-fat-progress-bar-wrapper {
    /* border: 1px green dotted; */
    flex-grow: 1;
    padding: 8px 16px 8px 0;
    height: 100%;
}

.big-fat-progress-bar {
    border-radius: 6px;
    box-shadow: 5px 4px 8px 0px #75848A1F;
    height: 100%;
    /* position: relative; */
}

.progress-bar-render {
    border-radius: 6px;
    /* position: absolute; */
    /* width: 5%; */
    /*background: linear-gradient(to right, var(--big-fat-progress-bar) 90%, rgba(0, 0, 0, 0));*/
    padding: 16px;
}

.new_bar{
    display: grid;
    grid-template-rows: repeat(2, 1fr);
}

.filters-and-selectors-wrapper {
    padding: 16px 8px 16px 0px;
    min-width: 25%;
}

.plot-block-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.plot-block-wrapper .graph-wrapper {
    flex-grow: 2;
    width: 100%;

    display: flex;
    flex-direction: row;

    /*height: 100%;*/
    /*border: 1px orange dotted;*/

    position: relative;
    height: 100%;
}

.graph-wrapper canvas{
    /*height: 100% !important;*/
}

.plot-block-wrapper .full-legend-wrapper {
    /*border: 1px red dotted;*/
    flex-grow: 1;
}

.full-screen-vertical-flex {
    display: flex;
    flex-direction: column;
    /*border: 1px green dotted;*/
    overflow-y: auto;
    overflow-x: hidden;
}

.special-pie-graph-container {
    /*border: 1px deeppink dotted;*/
    width: 25%;
    /*height: 100%;*/
}

.graph-selection-option {
    padding: 5px 16px 5px 16px;
    cursor: pointer;
}

.graph-selection-option:hover {
    padding: 5px 16px 5px 16px;
    cursor: pointer;
    background-color: var(--table-row-hover);
}

.graph-warning-block {
    border: 1px var(--bad-data-warning-red) dotted;
    padding:16px;
    word-break: break-word;
    line-height: 28px;
    border-radius: 6px;
    box-shadow: 5px 4px 8px 0px #75848A1F;

}





