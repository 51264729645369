*,
*::before,
*::after {
    box-sizing: border-box;
}

/* 1 – Изменим стили шрифтов */
/* 2 – Удалим margin в Firefox и Safari */
input[type="text"] {
    font-family: inherit;
    /* 1 */
    font-size: inherit;
    /* 1 */
    line-height: inherit;
    /* 1 */
    margin: 0;
    /* 2 */
}

.registration__page {
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
}

.form__position {
    padding: 10px;
    display: flex;
    /*justify-content: center;*/
    /*align-content: center;*/
}

.form__width {
    width: calc(var(--index) * 13);
}

.form__size {
    width: calc(var(--index) * 15);
    height: calc(var(--index) * 20);
}

.form__flex {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.form__flex-justify {
    display: flex;
    justify-content: center;
}

.text__block-size {
    width: calc(var(--index) * 17);
    text-align: center;
}

.text__block-size__h2 {
    margin-top: 20px;
    margin-bottom: 50px;
    width: calc(var(--index) * 13);
    text-align: center;
}

text__block-authorization-size__h2 {
    margin-top: 20px;
    margin-bottom: 10px;
    width: calc(var(--index) * 13);
    text-align: center;
}

.block__flex {
    display: flex;
    align-items: center;
}

.form__justify {
    justify-content: center;
}

.block__border {
    border-bottom: 2px solid var(--border-color-bottom);
}

.pointer {
    width: 20px;
    height: 20px;
    border-radius: 25px;
    background: var(--global-white-full);
}

.pointer__color {
    width: 20px;
    height: 20px;
    border-radius: 25px;
    background: var(--background-color-one);
}

.form__margin-top {
    margin-top: 25px;
}

.text__block-error {
    border-radius: 25px;
    text-align: center;
    border: 2px solid var(--border-color-error);
}

.block__none-margin-padding {
    margin: 0px;
    padding: 0px;
}

.block__confirmation {
    /*display: flex;*/
    width: 100%;
    height: 100% !important;
}

.block__confirmation--center {
    display: flex;
    justify-content: center;
    margin: calc(var(--index) * 14);
    background: var(--background-color-form);
    border-radius: 25px;
    border: 2px solid var(--background-color-form);
}

.project__info__form {
    height: auto;
    width: 50%;
    padding: 10px;
    margin: 10px;
    border-radius: 25px;
    background: var(--background-color-form);
}

.project__number {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    height: 26px;
    padding: 5px;
    padding-left: 20px;
    margin: 5px;
    border-radius: 25px;
    background: var(--background-color-white);
    box-shadow: 1px 2px 10px var(--box-shadow-grey);
}

.project__form-header__buttons {
    display: flex;
    justify-content: right;
    align-items: center;
}

.project__form-header__buttons img {
    width: 36px;
    height: 36px;
    margin: 5px;
}

.project__form__description {
    display: flex;
    flex-wrap: nowrap;
}

.project__form_description__head {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 150px;
    height: 26px;
}

.project__form_description__text {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
}

.project__form__elem {
    display: flex;
    flex-wrap: nowrap;
}

.shadow__block {
    padding: 5px;
    margin: 5px;
    border-radius: 25px;
    background: var(--background-color-white);
    box-shadow: 1px 2px 10px var(--box-shadow-grey);
}

.project__form_elem__block {
    width: 100%;
    margin: 5px;
}

.project__form_elem__list {
    width: 100%;
    list-style: none;

}

.project__form_elem__text {
    width: 100%;
    height: 36px !important;
    height: auto;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    border-radius: 25px;
    background: var(--background-color-white);
    box-shadow: 1px 2px 10px var(--box-shadow-grey);
    text-decoration: none;
}

.project__form_elem__text img {
    width: 26px;
    height: 26px;
    padding: 5px;
    margin: 5px;
}

.project__form_elem__list--center {
    width: 100%;
    list-style: none;
}

.project__form_elem__list--center li {
    width: 100%;
}

.project__form_elem__text--center {
    width: 100%;
    height: 36px !important;
    height: auto;
    margin-bottom: 5px;
    /*margin: 5px;*/
    /*padding: 5px;*/
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    background: var(--background-color-white);
    box-shadow: 1px 2px 10px var(--box-shadow-grey);
    text-decoration: none;
}

.global__form {
    height: auto;
    padding: 10px;
    margin: 10px;
    border-radius: 25px;
    background: var(--background-color-form);
}

.global__form-header {
    display: flex;
    flex-wrap: nowrap;
}

.global__form-header__name {
    display: flex;
}

.global__form-header__img {
    width: 26px;
    height: 26px;
    padding: 5px;
    margin: 5px;
    border-radius: 25px;
    background: var(--background-color-white);
    box-shadow: 1px 2px 10px var(--box-shadow-grey);
}

.global__form__img {
    width: 26px;
    height: 26px;
    padding: 5px;
    margin: 5px;
    border-radius: 25px;
    background: var(--background-color-white);
    box-shadow: 1px 2px 10px var(--box-shadow-grey);
}

.global__form__search {
    display: flex;
    margin: 5px;
    height: 36px;
    width: 250px;
    justify-content: end;
    align-items: center;
    border-radius: 25px;
    box-sizing: unset;
    background: var(--background-color-white);
    outline: 2px solid var(--border-color-bottom);
    /* Внутренняя граница */
    outline-offset: -2px;
    /* Смещение внутрь */
    margin-left: 5px;
}

.global__form__search img {
    padding: 5px;
    margin: 5px;
}

.global__form__search img:hover {
    cursor: pointer;
}

.global__form__search input {
    width: 220px;
    height: 36px;
    padding-left: 20px;
    border-radius: 20px;
    border: 0px;
}

.global__form__search input[type="search"]:focus {
    outline: none;
    /* Убираем выделение при фокусировке */
}

.global__form__img:hover {
    background: var(--background-color-menu);
    cursor: pointer;
}

.global__form__img img {
    width: 26px;
    height: 26px;
}

.global__form-header__text {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 420px;
    height: 26px;
    padding: 5px;
    padding-left: 20px;
    margin: 5px;
    border-radius: 25px;
    background: var(--background-color-white);
    box-shadow: 1px 2px 10px var(--box-shadow-grey);
}

.global__form-header__buttons {
    width: 100%;
    display: flex;
    justify-content: right;
}

.global__form-header__img img {
    width: 26px;
    height: 26px;
}

.projects-list {
    height: 200px;
    overflow-y: scroll
}

.projects-list img {
    width: 26px;
    height: 26px;
    padding: 5px;
    margin: 5px;
}

.projects-list h2 {
    height: 26px;
    /*padding: 5px;*/

    margin: 5px;
}

.project-item__input {
    padding: 5px;
    margin: 5px;
}

.project-item__text {
    display: flex;
    justify-content: center;
    width: 100%;
}

.project-item__text a {
    height: 26px;
    margin: 5px;
}

.project-item__procent {
    border-radius: 25px;
    display: flex;
    height: 32px;
    justify-content: left;
    background: var(--background-color-percentage);
    width: 100%;
    margin: 2px;
    overflow: hidden;
}

.project-item__description {
    display: flex;
    justify-content: left;
    align-items: center;
    overflow: hidden;
    min-width: 400px;
    max-width: 400px;
}

.project-item__procent-color {
    border-radius: 25px;
    height: 32px;
}

.project-item__procent-text {
    /*position: relative;*/
    /*position: absolute;*/
    /*background: #7C03A7;*/
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(var(--index) * 11.4);
    height: 32px;
}

.project-item__input input {
    width: 16px;
    height: 16px;
}

.project-item {
    display: flex;
    align-items: center;
    height: 36px;
    width: 98%;
    margin: 5px;
    border-radius: 25px;
    box-shadow: 1px 2px 10px var(--box-shadow-grey);
    background: var(--background-color-form);
}

.project-item:hover {
    cursor: pointer;
    background: var(--background-color-pink);
}

.dash__board--form {
    margin: 5px;
    /*width: calc(100vw - 310px);*/
    min-height: calc(100vh - 145px);
}

.dash__board--filter {
    padding: 5px;
}

.dash__board--filter select {
    border-radius: 25px;
    background: var(--background-color-white);
    box-shadow: 1px 2px 10px var(--box-shadow-grey);
    border: 0px;
    height: 36px;
}

.dash__board--filter select:hover {
    cursor: pointer;
}

.dash__board--select option {
    border-radius: 25px;
}

.dash__board--form-column {
    /* width: calc(100vw - 310px); */
    height: calc(100vh - 145px);
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
}

.dash__board--elem__list {
    display: flex;
    flex-direction: row;
    justify-content: left;
    position: relative;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    transform-origin: 0 0;
    transition: transform 1s linear;
    overflow-x: auto;
}


.dash__board--elem__head {
    display: flex;
    width: 100%;
}

.dash__board--elem__name {
    display: flex;
    align-items: center;
    height: 28px;
    width: 100%;
    margin: 5px;
    border-radius: 25px;
    background: var(--background-color-white);
    box-shadow: 1px 2px 10px var(--box-shadow-grey);
}

.dash__board--elem__name a {
    margin: 5px;
}

.dash__board--elem__settings {
    margin: 5px;
}

.dash__board--elem__settings img {
    width: 28px;
    height: 28px;
}

.dash__board--form-column__tasks {
    /*margin: 5px;*/
}

.dash__board--form-column__tasks .dash__board--form-column__tasks--list {
    /*overflow-y: scroll;*/
}

.dash__board--form-column__tasks--elem {
    background: var(--background-color-white);
    width: 98%;
    min-height: 150px;
    border-radius: 25px;
    margin: 5px;
}

/*label {*/
/*    font-family: sans-serif;*/
/*    font-size: 1rem;*/
/*    padding-right: 10px;*/
/*    background: #7C03A7;*/
/*}*/

/*select {*/
/*    font-size: 0.9rem;*/
/*    padding: 2px 5px;*/
/*}*/

.enother__menu {
    margin-left: 15px;
}

.right-aligned-part {
    display: flex;
    flex-direction: row;
    align-self: end;
}


/* Новая верстка формы входа */
.form_main {
    display: flex;
    flex-direction: row;
}

.form_main_center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.form_logo img{
    max-width: 100px;
}

.form_background {
    display: block;
    width: 50%;
    height: 100vh;

    opacity: 0.8;
    border-radius: 36px 0px 0px 36px;
    position: relative;
}

.form_background div{
    position: absolute;
    left: 80px;
    top: 50px;
}

.form_background img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.background_image_fullscreen {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 0;
    width: 100vw;
}

.form_container_fullscreen {
    z-index: 1;   
}

.form_container {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100vh;
    background-color: var(--background-color-white);
    align-items: center;
    justify-content: center;
}

.form_container_center {
    height: fit-content;
    width: fit-content;
    padding: 32px;
    border-radius: 20px;
    min-width: 550px;
}

.form_container_center .form_text {
    margin: 10px 0px;
}

.form_flex_row {
    display: flex;
    flex-direction: row;
}

.form_inner {
    display: flex;
    width: 484px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.form_input input {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1.5px solid #8FC6F1;
    background-color: var(--background-color-white);
    color: black;
}

.form_description {
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    max-width: 90%;
    color: var(--content-content-secondary, #75848A);
}

.form_header {
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    color: var(--content-content-primary, #12191C);
}

.bottom_buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
}