.header-component {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* width: calc(100vw - 300px); */
    width: 100%;
    height: 70px;
    z-index: 1000;
    background: var(--background-color-body) ;
    border-bottom:  1px solid  var(--border-gray);
    margin-bottom: 10px;
}

.right-control-panel-notification:hover {
    border-radius: 30px;
    background-color: var(--hover-color-blue) !important;
}

.right-control-panel-settings:hover {
    border-radius: 30px;
    background-color: var(--hover-color-blue) !important;
}

.header__component img {
    /*cursor: pointer;*/
    margin: 5px 20px;
}

.header__component img:hover {
    cursor: pointer;
    margin: 5px 20px;
    transform: scale(1.1);
}

.header__component-text a {
    width: 20px;
    margin: 10px 20px;
}

.header_icon_notification {
    margin-left: 5px;
    margin-right: 15px;
}