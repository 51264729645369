.user__mention--widget {
    margin: 30px;
    max-width: 540px;
}

.user__mention--widget__block {
    display: flex;
    align-items: center;
    padding: 10px 10px;
}

.user__mention--widget__block:hover {
    border-radius: 4px;
    cursor: pointer;
    background-color: var(--ui-color-hover-background-person);
}