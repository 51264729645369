/*@media (prefers-color-scheme: light) {*/
:root {
    /* Темы текста, прям цвета букв */
    --ui-color-text-main-light: white;
    --ui-color-text-main-dark: black;


    /* Темы навигатора вкладок */
    --ui-color-paginator-border-bottom: #E1E7EA;
    --ui-color-selected-tab-border-bottom: #2BACDB;
    --ui-color-selected-tab-text: #2BACDB;
    --ui-color-hover-tab-border-bottom: #2BACDB;
    --ui-color-hover-tab-text: #2BACDB;
    --ui-color-tab-text: #A9B7BC;

    /* Темы кнопки */
    --ui-color-hover-button: rgba(157, 157, 157, 0.25);
    --ui-color-a-button: #56666C;
    --ui-color-button-focus-border: #E1E7EA;
    --ui-color-button-main: #2BACDB;
    --ui-color-button-hover-light: #149cce6b;

    --positive-green-button-background-color: #d8f0cb;
    --normal-light-dark-button-background-color: #edf6f9;

    /* Темы абсолютного позиционирования */
    --ui-color-absolute-position: rgba(0, 0, 0, 0.5);
    --ui-color-absolute-position-button-confirm: rgb(20, 156, 206);
    --ui-color-absolute-position-button-cansel: rgb(223, 239, 244);
    --ui-color-absolute-position-button-cansel-text: #149CCE;
    --ui-color-absolute-position-button-confirm-text: rgb(223, 239, 244);
    --ui-color-absolute-position-head: #12191C;
    --ui-color-absolute-position-body: #56666C;

    /* Темы переключателя ios*/
    --ui-color-ios-chekbox-cheked: rgb(20, 156, 206);
    --ui-color-ios-chekbox-disabled: #56666C;

    /* Темы стандартного переключателя*/
    --ui-color-default-chekbox-cheked: rgb(20, 156, 206);

    /* Темы всплывающего окна теги пользователей */
    --ui-color-hover-background-person: rgba(157, 157, 157, 0.25);

    /* Темы всплывающего окна уведомления пользователя */
    --ui-color-notification-header-p: #8F9EA3;
    --ui-color-notification-header-h3: #000000;
    --ui-color-notification-text-color: #149CCE;
    --ui-color-notification-not-viewed: rgba(20, 156, 206, 0.144);
    --ui-color-notification-hover: rgba(20, 156, 206, 0.199);
    --ui-color-notification-point-color: #149CCE;
    --ui-color-notification-point-color-border: rgba(20, 156, 206, 0.432);

    /* Темы выпадающего листа */
    --ui-color-drop-down-border: #E1E7EA;
    --ui-color-drop-down-child-num-background: #007CAA;
    --ui-color-drop-down-child-num-color: rgb(223, 239, 244);

    /* Цвета графиков и диограмм */
    --graph-red-main: rgba(234, 85, 69, 1.0);
    --graph-red-fill: rgba(234, 85, 69, 0.3);

    --graph-pink-main: rgba(244, 106, 155, 1.0);
    --graph-pink-fill: rgba(244, 106, 155, 0.3);

    --graph-orange-main: rgba(239, 155, 32, 1.0);
    --graph-orange-fill: rgba(239, 155, 32, 0.3);

    --graph-yellow-main: rgba(237, 191, 51, 1.0);
    --graph-yellow-fill: rgba(237, 191, 51, 0.3);

    --graph-salat-main: rgba(189, 207, 50, 1.0);
    --graph-salat-fill: rgba(189, 207, 50, 0.3);

    --graph-green-main: rgba(135, 188, 69, 1.0);
    --graph-green-fill: rgba(135, 188, 69, 0.3);

    --graph-blue-main: rgba(39, 174, 239, 1.0);
    --graph-blue-fill: rgba(39, 174, 239, 0.3);

    --graph-purple-main: rgba(179, 61, 198, 1.0);
    --graph-purple-fill: rgba(179, 61, 198, 0.3);

    --graph-grid-color: rgba(0, 0, 0, 0.1);

    --graph-pie-data-label-color: black;

    /* Темы страницы разворота общих сведений по проекту */
    --bad-data-warning-red: red;
    --big-fat-progress-bar: #def1fa;
    --table-row-striped-highlight: #0e9cd512;
    --table-row-hover: #0e9cda22;

    /* Темы поиска с анимацией */
    --ui-color-animated-search-button-background: #FFFFFF;
    --ui-color-animated-search-child-background: #FFFFFF;
    --ui-color-animated-search-button-border: #E1E7EA;
    --ui-color-animated-search-input-color: #56666C;

    /* Темы для выпадашки простого поиска */
    --ui-color-light-search-color-head: #56666C;
    --ui-color-light-search-color-default-text: #56666C;
    --ui-color-light-search-color-found-text: #56666C;
    --ui-color-light-search-color-found-another-text: rgba(236, 249, 253, 1);
    --ui-color-light-search-color-searchable-text: #212121;
    --ui-color-lifht-search-background-hover: #E1E7EA;

    /* Темы для страницы глобального поиска */
    --ui-color-search-global-color-button: #FFFFFF;
    --ui-color-search-global-color-background: #149CCE;
    --ui-color-search-global-color-background-hover: rgba(20, 156, 206, 0.199);
    --ui-color-search-global-color-error-background: #ffc3c3;
    --ui-color-search-global-color-error-color: red;
    --ui-color-search-global-color-error-border: red;
    --ui-color-search-global-color-current-search-color: #56666C;
    --ui-color-search-global-color-current-search-background: rgba(20, 156, 206, 0.11);
    --ui-color-search-global-color-searchable-background: #091E420F;
    --ui-color-search-global-color-found-text: #56666C;
    --ui-color-search-global-color-found-another-text: rgba(236, 249, 253, 1);
    --ui-color-search-global-color-percentage: #149CCE;

    /* Темы для всплвающих подсказок */
    --ui-color-custom-tooltip-background: #FFFFFF;
    --ui-color-custom-tooltip-color: #56666C;
    --ui-color-custom-tooltip-border: #56666C;

}
/*}*/