.custom_table--full__window {
    display: block;
    overflow: auto;
    width: 100%;
    height: calc(100% - 25px - 20px) !important;
    margin-top: 16px;
}

.custom_table--full__window th {

    background: rgb(255, 255, 255);
    color: var(--table-header-color);
    font-size: 16px;
    font-weight: 600;
    line-height: 13px;
    width: 20%;
    border: none;
    text-align: start;
    padding: 5px 10px;

}

.custom_table--full__window td {

    text-align: start;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    padding: 5px 10px;
    color: var(--text-color-form-black);
    border: none;
    /*padding: 3px;*/

}

.custom_table {
    display: block;
    overflow: auto;
    width: 100%;
    height: 150px;
    /*position: relative;*/
    /*z-index: 1;*/
}

.custom_table table {
    width: 100%;
    /*position: relative;*/
    /*z-index: 1;*/
}

.custom_table thead {
    /*position: relative;*/
    /*z-index: 1;*/
    /*position: sticky;*/
    /*top: 0;*/
    /*z-index: 5;*/
}

/*.custom_table_thead {*/
/*    position: sticky;*/
/*    top: 0;*/
/*    z-index: 5;*/
/*}*/

.custom_table th {
    background: rgb(255, 255, 255);
    color: var(--table-header-color);
    font-size: 12px;
    font-weight: 400;
    line-height: 13px;
    border: none;
    text-align: start;
    /*position: relative;*/
    /*z-index: 1;*/
}

.custom_table tbody {
/*    position: relative;*/
/*    z-index: 1;*/
}

.custom_table td {
    /*width: 200px;*/
    text-align: start;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: var(--text-color-form-black);
    border: none;
    padding: 3px;
    height: 30px;
    overflow: hidden;
    /*position: relative;*/
    /*z-index: 1;*/
    /*position: relative;*/
    /*z-index: 1;*/
}

.task-name-new {
    height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.task-name-new:hover {
    cursor: pointer;
}

