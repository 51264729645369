.modal-window-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1001;
  }
  
.modal-window-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background-color: var(--background-color-white);
  width: 555px;
  min-height: 268px;
  height: fit-content;
  padding: 30px;
  border-radius: 6px;
}

.modal-window-btns{
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.modal-window-margin-btn{
  margin-left: 10px;
}