.widgets_task_container {
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    max-height: calc(100vh - 80px);
    /* max-width: calc(84vw); */
}

.widgets_task_container_sub {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 0px 10px 10px 10px;
    min-width: var(--widjets-width-size-542-px);
    background-color: var(--background-color-white);
    border-radius: 6px;
    box-shadow: 5px 4px 8px 0px #75848A1F;
    height: var(-moz-fit-content);
    height: fit-content;
}

.widgets_task_container_1 {
    display: flex;
    position: static;
    flex-direction: column;
    width: 80%;
    scroll-padding-bottom: 320px;
    overflow: scroll;
    overflow-x: hidden;
    padding-bottom: 10px;
    margin-bottom: 10px;
    /* max-height: 90vh; */
    /* min-height: 100%; */
    /*overflow-y: auto;*/

}

.widgets_task_container_2 {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 0px 10px 10px 10px;
    background-color: var(--background-color-white);
    border-radius: 6px;
    box-shadow: 5px 4px 8px 0px #75848A1F;
    /*height: 50%;*/
    height: fit-content;
    min-height: 50%;
    min-width: 300px;
    width: 20%;
}

.task_tabs--header {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    /*position: relative;*/
    z-index: 900;
    margin: 0px 0px 0px 10px;
    position: sticky;
    top: 0;
    background: var(--background-color-body);
}

.task__tabs {
    box-shadow: inset 0 -2px 0 #C5CFD3;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 20px;
    margin-bottom: -2px;
    border-bottom: -2px solid #C5CFD3;
}

.task-history-table-container .custom_table.history_project_table {
    height: auto;
}