#right-control-panel {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    padding: 16px 32px 16px 32px;
}

.headName {
    padding-left: 10px;
}

.headName .p-breadcrumb {
    background: none;
    border: none;
    padding: 1rem;
}

.headName .p-menuitem-text {
    color: #75848a;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
}

.headName a {
    color: #75848a;
    text-decoration: none;
}

.headName li:last-child > a > a {
    color: var(--text-color-form-black);
}

.headName nav .p-menuitem a {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
}

.header-notification-popup-container {
    position: absolute;
    z-index: 4000;
    top: 70px;
    right: 30px;
}

.header-settings-popup-container {
    z-index: 4000;
    position: absolute;
    top: 70px;
    right: 30px;
}

.right-control-panel-notification-num {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 6px;
    margin-right: 4px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: var(--ui-color-drop-down-child-num-color);
    background-color: var(--ui-color-drop-down-child-num-background);
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.85);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.85);
    }
}