.nav-panel {
    display: flex;
    flex-direction: column;
    padding: 10px 0px 10px 16px;
    font-size: 14px;
    font-weight: 500;
    overflow: scroll;
}

.menu-container.collapsed {
    align-items: center;
}

.nav-panel_project-container {
    margin-bottom: 5px;
}

.nav-panel_project-container.open {
    border: 1px solid var(--border-light-mode-border-primary, #e1e7ea);
    border-radius: 8px;
}

.nav-panel_item {
    /* padding: 10px 6px 10px 10px; */
    padding: 10px;
}

.menu-container.collapsed .nav-panel_item {
    width: fit-content;
}

.nav-panel a {
    text-decoration: none;
}

.nav-panel a.nav-panel_item {
    color: var(--text-color-form-black-black);
}

.nav-panel_label-container {
    width: fit-content;
    display: flex;
    cursor: pointer;
    align-items: center;
    gap: 10px;
}

.nav-panel_label-container img {
    margin-right: 10px;
}

.nav-panel_subproject .nav-panel_label {
    max-width: 130px;
}

.nav-panel_board .nav-panel_label {
    max-width: 155px;
}

.nav-panel_label {
    max-width: 180px;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    white-space: nowrap;
}

.nav-panel_project,
.nav-panel_board,
.nav-panel_subproject {
    padding: 10px 6px;
    padding-left: 40px;
}

.nav-panel_project {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--table-header-color);
    font-size: 14px;
    position: relative;
}

.nav-panel_board {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--table-header-color);
    font-size: 12px;
}

.nav-panel_subproject-board .nav-panel_board {
    padding-left: 40px;
}

.nav-panel_proxy-board,
.nav-panel_subproject-board .nav-panel_proxy-board {
    padding-left: 50px;
}

.board-icon {
    padding: 0px 6px 0px 0px;
}

.nav-panel_subproject {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    align-items: center;
    color: var(--text-color-form-black-black);
    font-size: 14px;
    color: #56666C;
}

.nav-panel_subproject-container {
    border-top: 1px solid var(--border-light-mode-border-primary, #e1e7ea);
    border-bottom: 1px solid var(--border-light-mode-border-primary, #e1e7ea);
}


.nav-panel_suproject-image {
    margin-right: 5px;
}

.nav-panel_subproject.highlighted {
    color: var(--text-color-widgets-blue);
}

.nav-panel_project-icons {
    display: flex;
    align-items: center;
}

.nav-panel_project-toggle {
    padding-left: 10px;
}

.nav-panel_projects-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-panel_project.highlighted,
.nav-panel_board.highlighted {
    background-color: var(--text-color-widgets-blue);
    border-radius: 8px;
    color: var(--text-color-form-white);
}

.nav-panel_project.with-mark::after {
    content: "";
    position: absolute;
    left: 0;
    top: 17%;
    height: 66%;
    width: 4px;
    background-color: #2BACDB;
}

.nav-panel_board:not(.highlighted):hover,
.nav-panel_project:not(.highlighted):hover,
.nav-panel_item:not(.highlighted):hover,
.nav-panel_subproject:not(.highlighted):hover {
    color: var(--text-color-widgets-blue) !important;
    background-color: #149CCE14;
    border-radius: 8px;
}


.nav-panel_item.highlighted {
    color: var(--text-color-widgets-blue) !important;
}

.nav-panel_item.highlighted {
    background: var(--text-color-widgets-blue);
    color: var(--text-color-form-white) !important;
    border-radius: 8px;
}

.image-placeholder {
    width: 24px;
    height: 24px;
}

.nav-panel_divider {
    padding: 8px 0px 6px 40px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 12.1px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #56666C;
    pointer-events: none;
}

.image-placeholder.project {
    background-image: url("../../../assets/projects_logo.svg");
}

.nav-panel_item.highlighted .image-placeholder.project {
    background-image: url("../../../assets/projects_logo_white.svg") !important;
}

.nav-panel_item:hover .image-placeholder.project {
    background-image: url("../../../assets/projects_logo_blue.svg");
}

.image-placeholder.monitor {
    background-image: url("../../../assets/monitor.svg");
}

.nav-panel_item.highlighted .image-placeholder.monitor {
    background-image: url("../../../assets/monitor_white.svg") !important;
}

.nav-panel_item:hover .image-placeholder.monitor,
.nav-panel_item.highlighted .image-placeholder.monitor {
    background-image: url("../../../assets/monitor_blue.svg");
}

.image-placeholder.archive {
    background-image: url("../../../assets/arhive.svg");
}

.nav-panel_item.highlighted .image-placeholder.archive {
    background-image: url("../../../assets/arhive_white.svg") !important;
}

.nav-panel_item:hover .image-placeholder.archive,
.nav-panel_item.highlighted .image-placeholder.archive {
    background-image: url("../../../assets/arhive_blue.svg");
}

.image-placeholder.trash {
    width: 24px;
    height: 24px;

    background-image: url("../../../assets/deleteElementGreyTrue.svg");
}

.nav-panel_item.highlighted .image-placeholder.trash {
    background-image: url("../../../assets/deleteElementWhite.svg") !important;
}

.nav-panel_item:hover .image-placeholder.trash,
.nav-panel_item.highlighted .image-placeholder.trash {
    background-image: url("../../../assets/deleteElementBlue.svg");
}
