

@media only screen and (max-width: 1200px ) {
    :root {
        --global-project-card-height: 480px;
        --global-project-card-width: 50%;
        --global-project-card-padding: 10px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1439px ) {
    :root {
        --global-project-card-height: 480px;
        --global-project-card-width: 33.333%;
        --global-project-card-padding: 10px;
    }
}

@media only screen and (min-width: 1440px) and (max-width: 1719px ) {
    :root {
        --global-project-card-height: 480px;
        --global-project-card-width: 25%;
        --global-project-card-padding: 10px;
    }
}

@media only screen and (min-width: 1720px) {
    :root {
        --global-project-card-height: 480px;
        --global-project-card-width: 20%;
        --global-project-card-padding: 10px;
    }
}

.projects_card_container {
    width: var(--global-project-card-width);
    padding: var(--global-project-card-padding);
    /* border: 1px solid red; */
}

h1 {
    margin: 0;
    padding: 0;
}

h1 {
    font-size: 100%;
    font-weight: normal;
}

.projects_none_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70vh;
}

.projects_none_container h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #313131;
}

.projects_main_container {
    display: flex;
    flex-direction: row;
    /*width: 100%;*/
    /* height: 87vh; */
    flex-wrap: wrap;
    /* padding: 15px; */
    height: calc(100vh - (80 + 36 + 10 * 1.5) * 1px);
    /* margin: 10px 5px; */
    overflow-y: auto;
    margin: 5px;
}

.projects_toolbar_container {
    margin: 5px;
    display: flex;
    align-items: center;
}
.projects-toolbar{
    padding: 5px !important;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background-color: white;
    margin: 0px 13px 0px 13px;
    border-radius: 8px;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    width: 100%;
}

.project-card-container {
    display: flex;
    flex-direction: column;
    height: var(--global-project-card-height);
    width: 100%;
    /* margin: 10px; */
    /* padding: 5px; */
    border-radius: 6px;
    cursor: pointer;
    background: #FFFFFF;
    position: relative;
    outline: 1px solid rgba(20, 156, 206, 0);
    transition: outline-color 0.3s linear, box-shadow 0.3s linear;
    /*overflow: hidden;*/
}

.project-card-img {
    /* flex: none; */
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    opacity: 0.8;
    width: 100%;
    /* width: var(--global-project-card-width); */
    height: 238px;
    overflow: hidden;
}

.background {
    /*linear-gradient(0deg, black, black) !important;*/
    transition: background-color 3s ease;
}


.project-card-img img {
    display: block;
    width: 100%;
    /* width: 100%; */
    height: 100%;
    object-fit: cover;
    border-radius: 6px 6px 0px 0px;

    transition: height 0.5s ease;
}

.card_projects_info {
    /*display: flex;*/
    /*flex-direction: column;*/
    /*align-items: flex-start;*/
    /*justify-content: space-between;*/
    padding: 28px;
    /* width: var(--global-project-card-width); */
    height: 242px;
    /* background: #FFFFFF; */
    box-shadow: -3px -2px 4px rgba(117, 132, 138, 0.12), 5px 4px 8px rgba(117, 132, 138, 0.12);
    border-radius: 0px 0px 6px 6px;
    z-index: 1;
    background-color: none;
    transition: background-color 3s ease;
    /* overflow: hidden; */
}

.card_projects_info img {
    margin-right: -10px !important;
}

.card_projects_progress {
    display: flex;
    flex-direction: column;
    /* width: 40%; */
}

.card_projects_code {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 0px 2px 0px;
}

.card_projects_footer {
    display: flex;
    flex-direction: row;
    /* width: 100%; */
    padding: 14px 0px 0px 0px;
    justify-content: space-between;
    align-items: center;
}

.project-card-text-name-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    /* width: 284px; */
    /*height: 70px;*/
    /*overflow: hidden;*/
}

.project-card-text-name-title-container {
    /* width: 284px; */
    height: 50px;
}


.project-card-text-name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #12191C;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

.text_Card_description_container{
    /*display: flex;*/
    /*align-items: end;*/

    /* width: 284px; */
    margin-top: 5px;
    height: 70px;
    /*margin-bottom: 10px;*/
    /*overflow-y: scroll;*/
}

.project-card-text-description {
    margin-block-end: 0px;
    margin-block-start: 0px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.02em;
    color: #75848A;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    height: 70px;
}

.text_Card_code {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    letter-spacing: -0.02em;
    color: #C5CFD3;
}

.card_projects_progress_item {
    display: flex;
    flex-direction: row;
    gap: 5px;
    /* width: 100%; */
    justify-content: space-between;
    margin-bottom: 5px;
}

.card_projects_footer .p-progressbar {
    background: var(--background-color-body);
    height: 8px;
}

.card_projects_footer .p-progressbar-label {
    display: none;
}

.card_projects_footer .p-progressbar-value {
    background: var(--background-color-blue);
}


.project-card-container:hover .project-card-img-wrap {
    border-radius: 6px 6px 0px 0px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)) !important;
}

.project-card-container:hover .card_projects_info {
    background: linear-gradient(0deg, rgba(117, 132, 138, 0.04), rgba(117, 132, 138, 0.04)), #FFFFFF;
    /* background-color: linear-gradient(0deg, rgba(117, 132, 138, 0.04), rgba(117, 132, 138, 0.04)); */
    box-shadow: -3px -2px 4px rgba(117, 132, 138, 0.12), 5px 4px 8px rgba(117, 132, 138, 0.12);
    border-radius: 0px 0px 6px 6px;
}

.project-card-container:hover .project-card-img img {
    height: 110%;
}

.project-card-boards-info {
    /*transition: outline-color 1s linear, box-shadow 0.2s linear;*/
    /*display: none;*/
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 20px;
    gap: 12px;

    position: absolute;
    /* width: 141px; */
    height: 40px;
    left: 28px;
    top: 18px;

    background: rgba(255, 255, 255, 0.7);
    border: 1px solid #E1E7EA;
    border-radius: 100px;
    z-index: 1000;
    transition: opacity 0.3s linear;
    opacity: 0;
}

.project-card-container:hover .project-card-boards-info {
    /* Frame 1321314252 */
    transition: opacity 0.3s linear;
    opacity: 1;
}

.project-card-container:hover .project-card-dates-period {
    transition: opacity 0.3s linear;
    opacity: 1;
}

.project-card-container:active .project-card-boards-info {
    display: none;
}

.project-card-container:active .project-card-dates-period {
    display: none;
}

.card_projects_boards_info_footer {
    display: none;
}

.project-card-container:active .card_projects_footer .card_projects_progress {
    display: none;
}

.project-card-container:active .card_projects_boards_info_footer {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 20px;
    gap: 12px;

    z-index: 10009;
}

.project-card-container .project-card-boards-info-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.project-card-container .text_margin {
    margin-right: 5px;
}

.card_projects_users {
    display: flex;
    flex-direction: row;
    margin-left: 12px;
    align-items: center;
}

.project-card-dates-period {
    display: block;
    z-index: 1000;
    position: absolute;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 140%;

    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: #ffffff;

    left: 200px;
    top: 30px;
    transition: opacity 0.3s linear;
    opacity: 0;
    /*display: none;*/
}


.count_users {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 10px;
    gap: 4px;
    /* width: auto; */
    height: 24px;
    background: #E1E7EA;
    border-radius: 22px;
    margin-left: 10px;
}

.project-card-container:hover {
    transition: outline-color 0.3s linear, box-shadow 0.3s linear;
    outline: 1px solid rgba(20, 156, 206, 0.47);
    box-shadow: 6px -6px 8px rgba(20, 156, 206, 0.47), -6px 6px 8px rgba(20, 156, 206, 0.47),
    6px 6px 8px rgba(20, 156, 206, 0.47), -6px -6px 8px rgba(20, 156, 206, 0.47);
}


.project-card-container:active {
    border: 2px solid #149CCE;
    border-radius: 6px;
    /*z-index: 10006;*/
}

.project-card-container:active .project-card-img-wrap {
    opacity: 0.8;
}

.project-card-container:active .card_projects_info {
    background: linear-gradient(0deg, rgba(20, 156, 206, 0.12), rgba(20, 156, 206, 0.12)), #FFFFFF;
    box-shadow: -3px -2px 4px rgba(117, 132, 138, 0.12), 5px 4px 8px rgba(117, 132, 138, 0.12);
    border-radius: 0px 0px 6px 6px;
}

.project-card-container:active .project-card-img img {
    height: 110%;
}

/* @media (min-width: 1870px) and (max-width: 1940px) {
    .projects_main_container {
        padding: 5px;
    }
} */