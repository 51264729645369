.nameProj_p_11_sub_header_widgets {
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    letter-spacing: -0.02em;
    color: #BABABA;
    margin: 0px;
}

.nameProj-sub-header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.nameProj-sub-header img {
    width: 16.67px;
    height: 16.67px;
}

.nameProj_heigth{
    height: 304px;
}

.board-subprojects-container {
    display: flex;
    flex-direction: column;
    height: 100px;
    overflow-y: auto;
}

.widget_wrapper--full__window .board-subprojects-container {
    height: 100%;
    overflow-y: scroll;
    max-height: 627px;
}

.nameProj-body-navigator>.ui__component--navigator {
    width: auto;
}


/* .nameProj_body .p-tabview-nav-link[aria-selected="true"]  {
    border-color: #f63b6a;
} */

/* .nameProj_body .p-tabview-nav-link[aria-selected="false"]  {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: var(--widjet-nameProj-gray-on-table);
} */


.nameProj_body .p-tabview .p-tabview-panels{
    padding: 0px;
    padding-top: 5px;
}

.nameProj_body .p-tabview .p-tabview-panels .m-0{
    margin-bottom: 0px;
}